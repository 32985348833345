import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import { userStore } from '~/stores/user';
import { CREATE_COMMENT, INCIDENT_TYPES_QUERY_LIST, INCIDENTS_QUERY_LIST, INSERT_INCIDENT, UPDATE_INCIDENT } from '~/graphql/incidents';
import dayjs from 'dayjs';
import type { Incident, IncidentType } from '~/types/incident';
import { cloneDeep } from '@apollo/client/utilities';

interface State {
  incidents: Incident[];
  incidentsType: IncidentType[];
  loading: boolean;
}

export const incidentStore = defineStore({
  id: 'incidentStore',
  state: (): State => ({
    incidents: [],
    incidentsType: [],
    loading: false,
  }),
  getters: {
    getIncidents: (state) => {
      return state.incidents;
    },
    getIncidentTypeAsInProgress: (state) => {
      return state.incidents.filter((incident) => incident.status === 'in_progress' || incident.status === 'alert');
    },
    getIncidentTypeAsDone: (state) => {
      return state.incidents.filter((incident) => incident.status === 'solved');
    },
    getIncidentTypeAsClose: (state) => {
      return state.incidents.filter((incident) => incident.status === 'closed');
    },
    getIncidentById: (state) => {
      return (id: number) => state.incidents.find((incident) => incident.id === id);
    },
    getOverCost: (state) => {
      return state.incidents
        .filter((incident) => incident.status === 'in_progress' || incident.status === 'alert')
        .reduce((acc, incident) => {
          return acc + (incident.cost ?? 0);
        }, 0);
    },
    getNumberOfIncidentsOpenThanMoreThat5Days: (state) => {
      return state.incidents
        .filter((incident) => incident.status === 'in_progress' || incident.status === 'alert')
        .filter((incident) => {
          const start = dayjs(incident._created_at);
          const today = dayjs();
          return today.diff(start, 'days') > 5;
        }).length;
    },
    getIncidentsType: (state) => {
      return state.incidentsType;
    },
  },
  actions: {
    async loadIncidents(): Promise<void> {
      this.loading = true;

      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      // Call API
      const incidentsResponse = await apolloClient.query<{ incidents: Incident[] }>({
        query: INCIDENTS_QUERY_LIST,
        variables: {
          site_id: globalStore().getSelectedSite,
        },
      });
      const incidentsTypeResponse = await apolloClient.query<{ incident_types: IncidentType[] }>({
        query: INCIDENT_TYPES_QUERY_LIST,
      });

      this.incidentsType = incidentsTypeResponse.data.incident_types;
      this.incidents = incidentsResponse.data.incidents;
      this.loading = false;
    },
    async insertIncident(incident: Partial<Incident>): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const data = await apolloClient.mutate({
        mutation: INSERT_INCIDENT,
        variables: { object: incident },
      });
      this.loadIncidents();

      const incidentInserted = data.data.insert_incidents_one;
      if (incident.owner_id !== userStore().getCurrentUser.id && incidentInserted) {
        await notificationStore().createNotification({
          type: 'incident-create-by-user',
          url: `/incident/${incidentInserted.id}`,
          created_for: incidentInserted?.owner_id,
          data: {
            incident_id: incidentInserted.id,
          },
          created_by: userStore().getCurrentUser.id,
          read: false,
        });
      }
    },
    async insertComment(id: number, comment: string): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const res = await apolloClient.mutate({
        mutation: CREATE_COMMENT,
        variables: { object: { content: comment, incident_id: id } },
      });

      const incidents = cloneDeep(this.incidents);
      const incident = incidents.findIndex((incident) => incident.id === id);
      incidents[incident].comments.push(res.data.insert_comments_one);
      this.incidents = incidents;

      const currentIncident = this.getIncidentById(id);
      if (currentIncident?.owner_id !== userStore().getCurrentUser.id) {
        await notificationStore().createNotification({
          type: 'incident-comment',
          url: `/incident/${id}`,
          created_for: currentIncident?.owner_id,
          data: {
            incident_id: id,
          },
          created_by: userStore().getCurrentUser.id,
          read: false,
        });
      }
    },
    async updateIncident(incidentId: number, incident: Partial<Incident>): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      await apolloClient.mutate({
        mutation: UPDATE_INCIDENT,
        variables: { id: incidentId, object: incident },
      });
      await this.loadIncidents();
    },
  },
});
