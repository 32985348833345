<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { userStore } from '~/stores/user';
import { useI18n } from 'vue-i18n';
import type { Incident } from '~/types/incident';

// Composables
const router = useRouter();
const { t } = useI18n();
const { incidentDeleteReason } = useConstants();

// Stores
const { showToast } = toastStore();

// Props and emits
const props = defineProps<{
  incident: Incident;
}>();
const modal = defineModel<boolean>();

// Data
const goToAction = ref(false);
const submiting = ref(false);

// Form
const schema = yup.object({
  is_incident: yup.string().required(t('incident.form.delete_reason_is_required')),
  closing_reason_other_text: yup.string().when('is_incident', {
    is: 'yes',
    then: () => yup.string().required(t('auth.form.field_required')),
  }),
});
const { handleSubmit, values } = useForm<{ closing_reason_other_text: string; is_incident: string }>({
  validationSchema: schema,
});

// Methods
const submit = handleSubmit(async (values) => {
  submiting.value = true;
  try {
    if (values.is_incident === 'yes') {
      await incidentStore().updateIncident(props.incident.id, {
        status: 'closed',
        closing_reason: 'other',
        closing_reason_other_text: values.closing_reason_other_text,
      });
      await incidentStore().insertComment(
        props.incident.id,
        t('incident.incident_closed_by', {
          value: userStore().getCurrentUser.first_name + ' ' + userStore().getCurrentUser.last_name,
        }),
      );
    } else {
      await incidentStore().updateIncident(props.incident.id, {
        status: 'closed',
        closing_reason: 'not_real_incident',
      });
      await incidentStore().insertComment(
        props.incident.id,
        t('incident.incident_closed_by', {
          value: userStore().getCurrentUser.first_name + ' ' + userStore().getCurrentUser.last_name,
        }),
      );
    }
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }

  if (goToAction.value) {
    goToAction.value = false;
    modal.value = false;
    showToast({ type: 'success', title: t('global.success'), message: t('incident.incident_closed') });
    router.push({ name: 'action-plan-index', query: { area_id: props.incident.area_id } });
  } else {
    showToast({ type: 'success', title: t('global.success'), message: t('incident.incident_closed') });
    modal.value = false;
    router.push({ name: 'incident-index' });
  }

  submiting.value = false;
});

// Computed
const incidentTypesAsOption = computed(() => {
  return incidentStore().getIncidentsType.map((type) => {
    return {
      key: type.value,
      label: t(`incidents.type_${type.value}`),
    };
  });
});
</script>
<template>
  <ui-modal v-model="modal" width="600" :title="$t('incidents.set_incident_status_to_solved')">
    <div class="grid grid-col-1">
      <ui-form-input-select
        :label="$t('incident.this_incident_is_an_incident_or_a_an error')"
        name="is_incident"
        :items="incidentDeleteReason"
        required
      />
      <ui-form-input-select
        v-if="values.is_incident === 'yes'"
        name="closing_reason_other_text"
        required
        :label="$t('global.root_cause')"
        :items="incidentTypesAsOption"
      />
    </div>
    <template #footer>
      <ui-button color="secondary" @click="submit()">{{ $t('incident.close_incident') }}</ui-button>
      <ui-button @click="submit(), (goToAction = true)">
        {{ $t('incident.close_incident_and_create_actions') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
